<template>
	<main>
		<category-banner
			v-if="category.banner"
			:banner="category.banner"
			:slides="category.carusel"
		/>
		<component-videos
			:videos="category.videoclipuri"
			v-if="category.videoclipuri && category.videoclipuri.length > 0"
		/>
		<testimonial-carousel />
		<component-gallery
			:images="images"
			v-if="
				category.galerie_imagini && category.galerie_imagini.length > 0
			"
		/>
		<section
			class="section section--larger"
			v-if="highlightedProducts && highlightedProducts.length > 0"
		>
			<div class="container">
				<h2 class="section__title has-text-centered">
					Produse {{ category.titlu }}
				</h2>
				<div class="columns is-multiline">
					<div
						class="column is-4 mb-5"
						v-for="product in highlightedProducts"
						:key="product.id"
					>
						<component-product :product="product" />
					</div>
				</div>
			</div>
		</section>
		<section
			class="section section--larger"
			v-if="highlightedArticles && highlightedArticles.length > 0"
		>
			<div class="container">
				<h2 class="section__title has-text-centered">
					Articole {{ category.titlu }}
				</h2>
				<div class="columns is-multiline">
					<div
						class="column is-4 mb-5"
						v-for="article in highlightedArticles"
						:key="article.id"
					>
						<component-article
							:article="article"
						></component-article>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
	import CategoryBanner from '@/components/TheCategoryBanner.vue';
	import ComponentProduct from '@/components/ComponentProduct.vue';
	import ComponentArticle from '@/components/ComponentArticle.vue';
	import TestimonialCarousel from '@/components/TheTestimonialCarousel.vue';
	import ComponentVideos from '@/components/ComponentVideos.vue';
	import ComponentGallery from '@/components/ComponentGallery.vue';

	import { mapGetters } from 'vuex';

	export default {
		metaInfo() {
			return {
				title: `Forever Diamond Team - ${this.category.titlu}`,
			};
		},
		components: {
			CategoryBanner,
			ComponentProduct,
			ComponentArticle,
			TestimonialCarousel,
			ComponentVideos,
			ComponentGallery,
		},
		computed: {
			...mapGetters('general', ['getCategoryByName']),
			category() {
				const routeName = this.$route.path.slice(
					this.$route.path.indexOf('/') + 1
				);
				return this.getCategoryByName(routeName)[0];
			},
			images() {
				return this.category.galerie_imagini.map(
					(img) =>
						`${process.env.VUE_APP_STRAPI_DATABASE_URL}/${img.imagine.url}`
				);
			},
			highlightedProducts() {
				return this.category.products.filter((prod) => {
					return prod.highlight_produs === true;
				});
			},
			highlightedArticles() {
				return this.category.articles.filter((art) => {
					return art.highlight_articol === true;
				});
			},
		},
	};
</script>

<style scoped lang="scss"></style>
